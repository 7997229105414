import { hash } from 'ohash'
import { myMessage } from '#imports'
import { consola, createConsola } from 'consola'
const request = async function (url, requestoptions) {
  const runtimeConfig = useRuntimeConfig()
  const logger = consola.withTag('stdout')
  let startTime = new Date().getTime()
  logger.info('request', url, JSON.stringify(requestoptions.params?._rawValue), 'request start time =>>>>', startTime)
  requestoptions.params = requestoptions.params || {}
  requestoptions.params.rqHash = hash(new Date().getTime())
  const result = await useFetch(
    url,
    {
      onRequest({ request, options }) {
        const token = useCookie('SDT_AccessToken')
        // options = options.assign({ ...requestoptions })
        const localeLang = useCookie('i18n_redirected').value
        let lang = 'zh_CN'
        if (localeLang) {
          if (localeLang == 'en') {
            lang = 'en_US'
          } else {
            lang = 'zh_CN'
          }
        }
        if (process.server) {
          options.baseURL = process.env.INTERNAL_API_BASE || runtimeConfig.public.apiBase
        } else {
          options.baseURL = process.env.NODE_ENV === 'development' ? '/api' : 'https://sdt-api.ok-skins.com/'
          // options.baseURL = process.env.NODE_ENV === 'development' ? '/api' : 'https://sdt-api-beta.ok-skins.com/'
        }
        options.headers = {
          'x-device-id': useCookie('SDT_DeviceId')._value || '',
          'x-device': 1,
          'x-app-version': '1.0.0',
          'access-token': token.value,
          'x-currency': useCookie('SDT_MonetaryUnit')._value?.currency || 'CNY',
          language: lang
        }
        options.noCache = true
        if (requestoptions?.method.toLowerCase() == 'post') {
          options.body = JSON.stringify(requestoptions.params?._rawValue || requestoptions.params)
          // console.log('options', options)
        } else {
          options.params = requestoptions.params?._rawValue || requestoptions.params
        }

        options.method = requestoptions?.method
        // options.lazy = true
        // console.log(1, request, options)
        // 设置请求头
      },
      onRequestError({ request, options, error }) {
        console.log('onRequestError', error)
        // 处理请求错误
        // myMessage({
        //   message: JSON.stringify(error),
        //   type: 'error'
        // })
      },
      onResponse({ request, response, options }) {
        // for (const [name, header] of response.headers) {
        //   if (name === 'trace_id') {
        //     return header.value // 返回 trace_id 的值
        //   }
        // }
        logger.info(
          'onResponse',
          url,
          'traceId =>>>>',
          response.headers.get('trace_id') || response.headers.get('eagleeye-traceid'),
          'res time ====>>>>>',
          new Date().getTime(),
          'request time =>>>>',
          new Date().getTime() - startTime,
          'status code =>>>>',
          response?._data?.status,
          'baseUrl =>>>',
          process.env.INTERNAL_API_BASE || runtimeConfig.public.apiBase
        )

        if (response?._data.errorCode == 401) {
          const token = useCookie('SDT_AccessToken')
          const mySteamToken = useCookie('SDT_MySteamToken')
          const store = useNuxtStore()
          token.value = null
          mySteamToken.value = null
          store.setUserInfo({})
        }
        if (response?._data?.errorCode === 0) {
          // console.log('请求成功', url)
          // response.data = response._data.data
          // myMessage({
          //   message: JSON.stringify(response._data),
          //   type: 'error'
          // })
        } else {
          console.log('response?.errorCode', response)
          if (response?.status == 200) {
            console.log('response?.errorCode', response?._data.errorCode)
            myMessage({
              message: response._data.errorMsg ?? JSON.stringify(response._data),
              type: 'error'
            })
          }
        }
        // 处理响应数据
        // localStorage.setItem('token', response._data.token)
      },
      onResponseError({ request, response, options }) {
        // 处理响应错误
      }
    },
    {
      key: url
    }
  )
  // console.log('result', result.data._rawValue?.errorCode)

  // if (result.status?.value === 'idle') {
  //   result.refresh()
  // } else {
  //   return result.data._rawValue
  // }
  return new Promise(async (resolve, reject) => {
    const maxRetry = 100
    const timeout = 100

    let retryCount = 0
    let timer

    const run = async () => {
      console.log('request-status', result.status?.value, url, new Date().getTime() - startTime, retryCount, maxRetry)
      try {
        if (result.status?.value === 'success') {
          resolve(result.data._rawValue)
        } else {
          if (retryCount == 0) {
            result.refresh()
            retryCount++
            clearTimeout(timer)
            timer = setTimeout(run, timeout)
          } else {
            if (retryCount < maxRetry) {
              retryCount++
              clearTimeout(timer)
              timer = setTimeout(run, timeout)
            } else if (retryCount == maxRetry) {
              console.log('reject', url)
              reject(result)
            }
          }
        }
      } catch (err) {
        reject(err)
      }
    }
    // timer = setTimeout(run, timeout)
    // if (!process.server) {
    //   setTimeout(() => {
    //     run()
    //   }, 300)
    // } else {
    //   run()
    // }
    run()
    // if (result.status?.value === 'success') {
    //   resolve(result.data._rawValue)
    // } else {
    //   console.log('refresh')
    //   result.refresh()
    // }
  })
  // if (result.status?.value === 'success' && result.data._rawValue?.errorCode === 0) {
  //   console.log('请求成功', url)
  //   return result.data._rawValue
  // } else {
  //   return result.data._rawValue
  // }
}

export default request
